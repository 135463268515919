import axios from 'axios';
import store from '../store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';


// Add a request interceptor
axios.interceptors.request.use(function (config) {
    let kittens = localStorage.getItem('kittens');
    if(kittens){
        config.headers.Authorization =  'Bearer '+kittens;
    }
    return config;
});
//Add a response interceptor
axios.interceptors.response.use(function (response){
    return Promise.resolve(response);
}, function (error){
    //if error unauthorized
    if (401 === error.response.status) {
        //logout
        localStorage.removeItem("kittens");
        window.location = '/login'
    }else if(422 === error.response.status){
        //if error user input 
        const err = error.response.data.errors;
        store.dispatch('error/setErr',err)
        let ret = [];
        for(var er in err){
            //check Is Object
            if(typeof(err[er]) === 'string'){
                ret.push(err[er]);
            }else{
                for(var dt in err[er]){
                  ret.push(err[er][dt])
                }
            }
        }
        store.dispatch('error/setError', ret);
    }else{
        let ret = [];
        ret.push('Internal server Error!');
        store.dispatch('error/setError', ret);
    }
    return Promise.reject(error);
});
export default (app) => {
    app.axios = axios;
    app.$http = axios;

    app.config.globalProperties.axios = axios;
    app.config.globalProperties.$http = axios;
}