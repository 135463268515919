<template>
  <div class="modal-backdrop offcanvas-menu fade d-none"></div>
    <footer class="sellermenu">
    <div class="bg-main">
      <ul class="nav nav-pills fixed-bottom nav-justified bg-white py-2 topshadow">
        <li class="nav-item">
          <router-link class="nav-link p-0" id="pesanan" to="/jual/pesanan-group"><i class="fas fa-home"></i><p class="small mb-0">Pesanan</p></router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link p-0" id="produk" to="/jual/produk"><i class="fa fa-shopping-basket"></i><p class="small mb-0">Produk</p></router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link p-0" id="pelanggan" to="/jual/pelanggan"><i class="fa fa-users"></i><p class="small mb-0">Pelanggan</p></router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link p-0" id="transaksi" to="/jual/transaksi"><i class="fa fa-money-bill"></i><p class="small mb-0">Transaksi</p></router-link>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script>
import { useRoute } from 'vue-router';
import { watchEffect } from 'vue';
import $ from 'jquery';

export default {
  setup() {
    const route = useRoute();
    watchEffect(() => {
      const url = route.path;
      if(url.includes('pesanan')){
        $('#pesanan').addClass('active');
      }
      if(url.includes('produk')){
        $('#produk').addClass('active');
      }
      if(url.includes('pelanggan')){
        $('#pelanggan').addClass('active');
      }
    })
    
  },
}
</script>