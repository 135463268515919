import { createApp } from 'vue';
import App from './pages/App.vue';
import router from './router';
import http from './http';
import store from './store';
import './bootstrap.js';
//sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
//phone input
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import VueSocialSharing from 'vue-social-sharing';

const VueTelInputOptions = {
    mode: "international",
    onlyCountries: ['ID'],
    defaultCountry: 'ID'
}

const app = createApp(App)
app
.use(http)
.use(store)
.use(router)
.use(VueSweetalert2)
.use(VueTelInput, VueTelInputOptions)
.use(VueSocialSharing)
.mount('#app')
