import { createWebHistory, createRouter } from "vue-router";
import store from '../store'

function loadView(view) {
    return () => import(`../pages/${view}.vue`);
}
//layout
import LayoutAdmin from '../components/admin/Layout.vue';
import LayoutUser from '../components/user/Layout.vue';

const myRoutes = [
    {
        path: '/',
        name: 'Index',
        component: loadView('home/Index'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: loadView('guest/Login'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: loadView('guest/Register'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: loadView('guest/ForgotPassword'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/home/po/:id',
        name: 'home-Po',
        component: loadView('home/PoGroup'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/home/pgroup/:id',
        name: 'home-ProdukGroup',
        component: loadView('home/ProdukGroup'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/home/pcategory/:id',
        name: 'home-ProdukCategory',
        component: loadView('home/ProdukCategory'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/home/produk/:id',
        name: 'home-Produk',
        component: loadView('home/Produk'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/home/qty/:id',
        name: 'home-Qty',
        component: loadView('home/Qty'),
        meta: {
            auth: false,
        }
    },
    //admin login
    {
        path: '/admin',
        name: 'Admin',
        component: loadView('admin/Login'),
        meta: {
            auth: false,
        }
    },
    //admin
    {
        path: '/admin',
        component: LayoutAdmin,
        children: 
        [
            {
                path: 'dashboard',
                name: 'Admin-Dashboard',
                component: loadView('admin/Dashboard'),
                meta: {
                    auth: true,
                    role: 'admin',
                }
            },
            {
                path: 'pengguna',
                name: 'Admin-Pengguna',
                component: loadView('admin/pengguna/Index'),
                meta: {
                    auth: true,
                    role: 'admin',
                }
            },
            {
                path: 'toko',
                name: 'Admin-Toko',
                component: loadView('admin/pengguna/Toko'),
                meta: {
                    auth: true,
                    role: 'admin',
                }
            },
            {
                path: 'reseller',
                name: 'Admin-Reseller',
                component: loadView('admin/pengguna/Reseller'),
                meta: {
                    auth: true,
                    role: 'admin',
                }
            },
            {
                path: 'supplier',
                name: 'Admin-Supplier',
                component: loadView('admin/pengguna/Supplier'),
                meta: {
                    auth: true,
                    role: 'admin',
                }
            },
            {
                path: 'pelanggan',
                name: 'Admin-Pelanggan',
                component: loadView('admin/pengguna/Pelanggan'),
                meta: {
                    auth: true,
                    role: 'admin',
                }
            },
            {
                path: 'produk',
                name: 'Admin-Produk',
                component: loadView('admin/produk/Index'),
                meta: {
                    auth: true,
                    role: 'admin',
                }
            },
            {
                path: 'produk-group',
                name: 'Admin-Produk-Group',
                component: loadView('admin/produk/Group'),
                meta: {
                    auth: true,
                    role: 'admin',
                }
            },
            {
                path: 'produk-jenis',
                name: 'Admin-Produk-Jenis',
                component: loadView('admin/produk/Jenis'),
                meta: {
                    auth: true,
                    role: 'admin',
                }
            },
        ]
    },
    //user
    {
        path: '/jual',
        component: LayoutUser,
        children: 
        [
            {
                path: 'lisensi',
                name: 'Jual-Lisensi',
                component: loadView('user/License'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'bantuan',
                name: 'Jual-Bantuan',
                component: loadView('user/Bantuan'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'notifikasi',
                name: 'Jual-Notifikasi',
                component: loadView('user/Notifikasi'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pengaturan',
                name: 'Jual-Pengaturan',
                component: loadView('user/jual/Pengaturan'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'penjualan',
                name: 'Jual-Penjualan',
                component: loadView('user/jual/penjualan/index'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            //produk
            {
                path: 'produk',
                name: 'Jual-Produk',
                component: loadView('user/produk/produk/Index'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk/tambah',
                name: 'Jual-Produk-Tambah',
                component: loadView('user/produk/produk/Tambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk/info/:id',
                name: 'Jual-Produk-Info',
                component: loadView('user/produk/produk/Info'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk/edit/:id',
                name: 'Jual-Produk-Edit',
                component: loadView('user/produk/produk/Edit'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk/pesanan/:id',
                name: 'Jual-Produk-Pesanan',
                component: loadView('user/produk/Pesanan'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk-jenis',
                name: 'Jual-ProdukJenis',
                component: loadView('user/produk/jenis-produk/Index'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk-jenis/tambah',
                name: 'Jual-ProdukJenis-Tambah',
                component: loadView('user/produk/jenis-produk/Tambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk-jenis/info/:id',
                name: 'Jual-ProdukJenis-Info',
                component: loadView('user/produk/jenis-produk/Info'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk-jenis/edit/:id',
                name: 'Jual-ProdukJenis-Edit',
                component: loadView('user/produk/jenis-produk/Edit'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk-group',
                name: 'Jual-ProdukGroup',
                component: loadView('user/produk/group/Index'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk-group/tambah',
                name: 'Jual-ProdukGroup-Tambah',
                component: loadView('user/produk/group/Tambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk-group/detail/:id',
                name: 'Jual-ProdukGroup-Detail',
                component: loadView('user/produk/group/Detail'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk-group/edit/:id',
                name: 'Jual-ProdukGroup-Edit',
                component: loadView('user/produk/group/Edit'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'produk-group/info/:id',
                name: 'Jual-ProdukGroup-Info',
                component: loadView('user/produk/group/Info'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            //pelanggan
            {
                path: 'pelanggan',
                name: 'Jual-Pelanggan',
                component: loadView('user/pelanggan/Index'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pelanggan/tambah',
                name: 'Jual-Pelanggan-Tambah',
                component: loadView('user/pelanggan/Tambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pelanggan/info/:id',
                name: 'Jual-Pelanggan-Info',
                component: loadView('user/pelanggan/Info'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pelanggan/edit/:id',
                name: 'Jual-Pelanggan-Edit',
                component: loadView('user/pelanggan/Edit'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pelanggan/group',
                name: 'Jual-Pelanggan-Group',
                component: loadView('user/pelanggan/Group'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pelanggan/group/tambah',
                name: 'Jual-Pelanggan-Group-Tambah',
                component: loadView('user/pelanggan/GroupTambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pelanggan/group/edit/:id',
                name: 'Jual-Pelanggan-Group-Edit',
                component: loadView('user/pelanggan/GroupEdit'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            //reseller
            {
                path: 'pelanggan/reseller',
                name: 'Jual-Pelanggan-Reseller',
                component: loadView('user/reseller/Index'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pelanggan/reseller/tambah',
                name: 'Jual-Pelanggan-Reseller-Tambah',
                component: loadView('user/reseller/Tambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pelanggan/reseller/info/:id',
                name: 'Jual-Pelanggan-Reseller-Info',
                component: loadView('user/reseller/Info'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pelanggan/reseller/edit/:id',
                name: 'Jual-Pelanggan-Reseller-Edit',
                component: loadView('user/reseller/Edit'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            //suplier
            {
                path: 'supplier',
                name: 'Jual-Supplier',
                component: loadView('user/supplier/Index'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'supplier/tambah',
                name: 'Jual-Supplier-Tambah',
                component: loadView('user/supplier/Tambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'supplier/info/:id',
                name: 'Jual-Supplier-Info',
                component: loadView('user/supplier/Info'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'supplier/edit/:id',
                name: 'Jual-Supplier-Edit',
                component: loadView('user/supplier/Edit'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            //pesanan-group
            {
                path: 'pesanan-group',
                name: 'Jual-Pesanan-Group',
                component: loadView('user/pesanan/Group'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pesanan-group/tambah',
                name: 'Jual-Pesanan-Group-Tambah',
                component: loadView('user/pesanan/GroupTambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pesanan-group/info/:id',
                name: 'Jual-Pesanan-Group-Info',
                component: loadView('user/pesanan/GroupInfo'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pesanan-group/edit/:id',
                name: 'Jual-Pesanan-Group-Edit',
                component: loadView('user/pesanan/GroupEdit'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pesanan/tambah',
                name: 'Jual-Pesanan-Tambah',
                component: loadView('user/pesanan/PesananTambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pesanan/customer/:id',
                name: 'Jual-Pesanan-Customer',
                component: loadView('user/pesanan/Customer'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pesanan/edit/:id',
                name: 'Jual-Pesanan-Edit',
                component: loadView('user/pesanan/PesananEdit'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pesanan/produk/:id',
                name: 'Jual-Pesanan-Produk',
                component: loadView('user/pesanan/Produk'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            //non-po
            {
                path: 'pesanan-nonPo/tambah',
                name: 'Jual-Pesanan-NonPo-Tambah',
                component: loadView('user/nonPo/Tambah'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pesanan-nonPo/info/:id',
                name: 'Jual-Pesanan-NonPo-Info',
                component: loadView('user/nonPo/Info'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'pesanan-nonPo/rekap/:id',
                name: 'Jual-Pesanan-NonPo-Rekap',
                component: loadView('user/nonPo/Rekap'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            //transaksi
            {
                path: 'transaksi',
                name: 'Jual-Transaksi',
                component: loadView('user/transaksi/Index'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'transaksi/info/:id',
                name: 'Jual-Transaksi-Info',
                component: loadView('user/transaksi/Info'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
            {
                path: 'transaksi/upload/:id',
                name: 'Jual-Transaksi-Upload',
                component: loadView('user/transaksi/Upload'),
                meta: {
                    auth: true,
                    role: 'seller',
                }
            },
        ]
    }
]
const router = createRouter({
    hashbang: false,
    history: createWebHistory(),
    routes: myRoutes,
    linkActiveClass: "active",
    // linkExactActiveClass: "exact-active",
});
//midleware routes
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.auth)) {
      if (store.state.auth.authenticated) {
        next()
        return
      }
      next('/login')
    } else {
      next()
    }
})
export default router;