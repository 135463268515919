//state
const state = () => ({
    show: false,
    error: false, //error == true || success == false
    msg: [],
    err: {},
})
//mutations 
const mutations = {
    setError(state, {data}){
        state.show = true;
        state.error = true;
        state.msg = data;
    },
    setSuccess(state, {data}){
        state.show = true;
        state.error = false;
        state.msg = data;
    },
    setDefault(state){
        state.show = false;
        state.error = false;
        state.msg = [];
        state.err = {};
    },
    setErr(state, {data}){
        state.err = data
    }
}
//actions
const actions = {
    setError({commit}, data){
        return commit('setError', {data: data})
    },
    setSuccess({commit}, data){
        return commit('setSuccess', {data: data})
    },
    setDefault({commit}){
        return commit('setDefault');
    },
    setErr({commit}, data){
        return commit('setErr', {data: data})
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}