import axios from 'axios';

class AuthService {
  checkUser(){
    return axios.get('/api/checkUser');
  }
  login(user) {
    return axios
      .post('/api/login', user)
      .then(response => {
        if (response.data.success) {
          localStorage.removeItem('kittens');
          localStorage.setItem('kittens', response.data.access_token);
        }

        return response.data;
      });
  }

  logout() {
    return axios.post('/api/logout')
           .then((res) => {
             return res.data
           });
  }
}

export default new AuthService();
