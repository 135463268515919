<template>
    <div class="h-100vh d-flex flex-column">
        <router-view />
        <Footer />
    </div>
</template>
<script>
import Footer from './Footer.vue';
import {reactive, onMounted} from 'vue';
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    components: {
        Footer
    },
    setup(){
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            loading: false
        })
        onMounted(() => {
            if (store.state.auth.authenticated) {
                if (store.state.auth.role === "seller") {
                    // router.push({ path: "/jual/pesanan-group" });
                } else if (store.state.auth.role === "admin") {
                    router.push({ path: "/admin/dashboard" });
                }
            }
        })
        return {
            state
        }
    }
}
</script>