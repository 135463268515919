import AuthService from '../../api/auth.service.js';

//state
const kittens = localStorage.getItem("kittens")
const state = () => ({
    users: [],
    authenticated: kittens ? true : false,
    role: 'seller',
    first: false,
})
//getters
const getters = {
    isLoggin: (state) => {
        return state.authenticated
    }
}
// mutations
const mutations = {
    setFirst(state) {
        state.first = true
    },
    loginSuccess(state) {
        state.authenticated = true
    },
    loginFailure(state) {
        state.authenticated = false
    },
    logout(state) {
        state.authenticated = false
        state.users = []
        state.role = 'seller'
    },
    setAdmin(state, { data }){
        state.role = data;
    }
}

//actions
const actions = {
    login({ commit }, data) {
        return AuthService.login(data).then(
            res => {
                if (res.success) {
                    commit('loginSuccess');
                    if(res.user.first_login){
                        commit('setFirst');
                    }
                }
                return Promise.resolve(res);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    logout({ commit }) {
        return AuthService.logout().then(
            () => {
                commit('logout');
                localStorage.removeItem('kittens');
                window.location = '/login'
            },
            () => {

            }
        );
    },
    isAdmin({ commit }){
        return AuthService.checkUser().then((res) => {
            if (res.data.success) {
                commit('setAdmin', { data: res.data.role })
            }
        })
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}