<template>
  <router-view />
</template>
<script>
import $ from "jquery";
import { onMounted } from "vue";

export default {
  name: "App",
  setup() {
    onMounted(() => {
      // Sidebar Controller
      $("#body-sidemenu .collapse").collapse("hide"); // Hide submenus
      $("#collapse-icon").addClass("fa-angle-double-left"); // Collapse/Expand icon
      $("[data-toggle=sidebar-colapse]").click(function () {
        // Collapse click
        SidebarCollapse();
      });
      function SidebarCollapse() {
        $(".menu-collapsed").toggleClass("d-none");
        $(".sidebar-submenu").toggleClass("d-none");
        $(".submenu-icon").toggleClass("d-none");
        $("#sidebar-container").toggleClass(
          "sidebar-expanded sidebar-collapsed"
        );
        $(".sidebar-separator-title").toggleClass("d-flex");
        $("#collapse-icon").toggleClass(
          "fa-angle-double-left fa-angle-double-right"
        );
      }
      $(window).on("load", function () {
        function viewport() {
          var e = window,
            a = "inner";
          if (!("innerWidth" in window)) {
            a = "client";
            e = document.documentElement || document.body;
          }
          return { width: e[a + "Width"], height: e[a + "Height"] };
        }
        // Sidebar collapse on mobile
        function check() {
          if (viewport().width < 768) {
            SidebarCollapse();
            $("#main-content").toggleClass("with-fixed-sidebar");
            $("#sidebar-container").toggleClass("w-100");
            $("#sidebar-container").toggleClass("h-default");
            $("#sidebar-container").toggleClass("border-right");
          }
        }
        check();
        var width = $(window).width();
        $(window).resize(function () {
          if ($(window).width() == width) return;
          width = $(window).width();
          check();
        });

        // BS Collapse on mobile
        function collapseSmall() {
          if (viewport().width < 768) {
            $(".collapse-sm").removeClass("show");
          } else {
            $(".collapse-sm").addClass("show");
          }
        }
        collapseSmall();
      });
      // end SidebarCollapse
      // Header scroll class
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $("#header").addClass("header-scrolled");
        } else {
          $("#header").removeClass("header-scrolled");
        }
      });
      if ($(window).scrollTop() > 100) {
        $("#header").addClass("header-scrolled");
      }
    });
  },
};
</script>
<style>
.error {
  color: #c1272d;
  font-style: italic;
}
</style>