<template>
    <div id="admindash" class="adminpage">
        <Header />
        <router-view />
    </div>
</template>
<script>
import Header from './Header';

export default {
    components: {
        Header,
    }
}
</script>