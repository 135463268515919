<template>
    <header id="header" class="border-bottom bg-light shadow-sm">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-md navbar-light bg-faded px-0 px-sm-3">
        <router-link class="navbar-brand bg-transparent rounded p-0 text-" to="/">POsenan</router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="collapsibleNavbar">
          <ul class="navbar-nav w-100">
            <li class="nav-item mt-1 mb-n1 active">
              <router-link class="nav-link" title="Dashboard" to="/admin/dashboard">Dasbor</router-link>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <div class="dropdown">
                <a href="javascript:void(0);" class="nav-link" title="Pengguna" data-toggle="dropdown">Pengguna</a>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" to="/admin/pengguna">Pengguna</router-link>
                  <router-link class="dropdown-item" to="/admin/toko">Toko</router-link>
                  <router-link class="dropdown-item" to="/admin/reseller">Reseller</router-link>
                  <router-link class="dropdown-item" to="/admin/supplier">Supplier</router-link>
                  <router-link class="dropdown-item" to="/admin/pelanggan">Pelanggan</router-link>
                </div>
              </div>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <div class="dropdown">
                <a href="javascript:void(0);" class="nav-link" title="Pengguna" data-toggle="dropdown">Produk</a>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" to="/admin/produk">Produk</router-link>
                  <router-link class="dropdown-item" to="/admin/produk-group">Produk Group</router-link>
                  <router-link class="dropdown-item" to="/admin/produk-jenis">Jenis Produk</router-link>
                </div>
              </div>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <div class="dropdown">
                <a href="javascript:void(0);" class="nav-link" title="Transaksi" data-toggle="dropdown">Transaksi</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="./admin-transaksi.html">Rekap</a>
                  <a class="dropdown-item" href="./admin-transaksi-aktif.html">Transaksi Aktif</a>
                  <a class="dropdown-item" href="./admin-transaksi-riwayat.html">Riwayat</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="./admin-pengiriman.html">Pengiriman</a>
                  <a class="dropdown-item" href="./admin-pengiriman-vendor.html">Vendor</a>
                </div>
              </div>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <div class="dropdown">
                <a href="javascript:void(0);" class="nav-link" title="Diskusi" data-toggle="dropdown">Diskusi</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="./admin-diskusi.html">Rekap</a>
                  <a class="dropdown-item" href="./admin-diskusi-aduan.html">Pengaduan</a>
                  <a class="dropdown-item" href="./admin-diskusi-chat.html">Chat</a>
                  <a class="dropdown-item" href="./admin-diskusi-komplain.html">Komplain</a>
                  <a class="dropdown-item" href="./admin-diskusi-ulasan.html">Ulasan</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="./admin-diskusi-bantuan.html">Topik Bantuan</a>
                  <a class="dropdown-item" href="./admin-diskusi-kategori.html">Kategori</a>
                </div>
              </div>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <div class="dropdown">
                <a href="javascript:void(0);" class="nav-link" title="Promo" data-toggle="dropdown">Promo</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="./admin-promo.html">Rekap</a>
                  <a class="dropdown-item" href="./admin-promo-aktif.html">Promo Aktif</a>
                  <a class="dropdown-item" href="./admin-promo-sponsor.html">Sponsor</a>
                  <a class="dropdown-item" href="./admin-promo-riwayat.html">Riwayat</a>
                </div>
              </div>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <div class="dropdown">
                <a href="javascript:void(0);" class="nav-link" title="Pengaturan" data-toggle="dropdown">Pengaturan</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="./admin-pengaturan.html">Pengaturan Umum</a>
                  <a class="dropdown-item" href="./admin-pengaturan-kstatis.html">Konten Statis</a>
                  <a class="dropdown-item" href="./admin-pengaturan-kdinamis.html">Konten Dinamis</a>
                  <a class="dropdown-item" href="./admin-pengaturan-lokasi.html">Lokasi</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="./admin-pengaturan-loguser.html">Log User</a>
                  <a class="dropdown-item" href="./admin-pengaturan-logsistem.html">Log Sistem</a>
                </div>
              </div>
            </li>
            <li class="nav-item mt-1 mb-n1 mr-auto">
              <div class="dropdown">
                <a href="javascript:void(0);" class="nav-link" title="Laporan" data-toggle="dropdown">Laporan</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="./admin-laporan.html">Rekap</a>
                  <a class="dropdown-item" href="./admin-laporan-manajemen.html">Manajemen</a>
                </div>
              </div>
            </li>
            <li class="nav-item text-nowrap pt-2 pl-lg-5">
              <div class="dropdown">
                <a href="javascript:void(0);" title="Notifications" data-toggle="dropdown"><i class="fa fa-bell h5"></i><span class="badge badge-pill badge-secondary align-top">7</span></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item mw-250px text-truncate" href="#">Lorem ipsum dolor sit amet consectetur</a>
                  <a class="dropdown-item mw-250px text-truncate" href="#">Neque porro quisquam est</a>
                  <a class="dropdown-item mw-250px text-truncate" href="#">Nisi ut aliquid ex ea commodi consequatur</a>
                  <a class="dropdown-item mw-250px text-truncate" href="#">Quis autem vel eum iure reprehenderit qui in ea voluptate</a>
                  <a class="dropdown-item mw-250px text-truncate" href="#">Adispiscing quisquam ex ea porro</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="./admin-notifikasi.html">Lihat semua (7)</a>
                </div>
              </div>
            </li>
            <li class="nav-item text-nowrap pt-2 pl-lg-4">
              <div class="dropdown">
                <a href="javascript:void(0);" title="Settings" data-toggle="dropdown">Admin <i class="fa fa-cog"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="./admin-dasbor.html">Dasbor</a>
                  <a class="dropdown-item" href="./admin-notifikasi.html">Notifikasi</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="./admin-profil.html">Profil</a>
                  <a class="dropdown-item" href="./admin-riwayat.html">Riwayat</a>
                  <a class="dropdown-item" href="./admin-bantuan.html">Bantuan</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="javascript:void(0);" @click.prevent="logout">Logout</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>
<script>
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const logout = () => {
      store.dispatch("auth/logout");
    };
    return {
      logout
    }
  },
}
</script>